.terminal-promptbox {
    position: relative;
  }

  .terminal-promptbox promptbox {
    padding-left: 25px; /* Add padding for the ">" character */
  }

  .terminal-promptbox::before {
    content: '>';
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    color: green;
  }