#link {
    margin: 0 auto;
    padding-left: auto;
    padding-right: auto;
    color: white;
    justify-content: space-between;
    align-items: center;
    max-width: 60%;
    height: 250px;
  }
  
  @media screen and (max-width: 768px) {
    #link {
      max-width: 95%;
    }
  }
  