#Moon-Sun icon placement
{
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem;
    font-size: 2rem;
    cursor: pointer;
    color: var(--white);
    z-index: 100;
}

body {
    scroll-padding-top: 70px; /* Height of your header */
  }
  
  