video#background-landing[style*='darkreader']
{
    color-scheme:light !important;
}


@keyframes wobble {
    0% { transform: translateX(0%); }
    15% { transform: translateX(-10px) rotate(-6deg); }
    30% { transform: translateX(10px) rotate(6deg); }
    45% { transform: translateX(-10px) rotate(-3.6deg); }
    60% { transform: translateX(10px) rotate(3.6deg); }
    75% { transform: translateX(-10px) rotate(-1.2deg); }
    100% { transform: translateX(0%); }
  }


  #home:hover {
    animation: wobble 1s infinite;
  }
  

  .wobble:hover {
    animation: wobble 2s infinite;
  }
  
