/* Hide scrollbar for Chrome, Safari, and Opera */
::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for Firefox */
html {
    scrollbar-width: none;  /* Firefox 64 */
    overflow: -moz-scrollbars-none; /* Firefox 65 and later */
}

/* Hide scrollbar for IE and Edge */
html {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none; /* Edge */
}

/* Disable horizontal scroll when the component is mounted */
body {
    overflow-x: hidden;
}

/* Enable horizontal scroll when the component is unmounted */
body {
    overflow-x: unset;
}
