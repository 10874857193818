
.react-pdf__Page__textContent.textLayer {
    display: none;
}

.buttons {
    transition: max-height 5s ease-out, opacity 5s ease-out;
    overflow: hidden;
}

.buttons-hidden {
    opacity: 0;
    max-height: 0;
}

.buttons-visible {
    opacity: 1;
    max-height: 200px; /* adjust this value as necessary */
}

